  * {
  margin: 0;
  padding: 0; 
  box-sizing: border-box;
  font-family: monospace;
}

html, body{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body{
  background: #f1f1f8;
  position: auto;
  overflow: auto;
}

h1,p {
	color: black;
}

#intro{
	color: black;
	top: 50%;
	left: 50%;
	position: absolute;
	transform: translateX(-50%) translateY(-50%) scale(2, 2);
	transition: 1s ease-in-out;
}

#aboutTabBar{
  margin-top: 2%;
  box-sizing: border-box;
}

h1.translate {
  top: 0%;
	transform: translateX(-50%);
  opacity: 0;
}

h1.disappear {
	opacity: 0;
}


#main {
  opacity: 0;
	color: white;
	margin-top: 0px;
}


.main-fade {
	opacity: 1;
	color: white;
  transition: 0.5s ease-in-out;
}

.main-normal {
	opacity: 1;
	color: white;
}

.App {
  text-align: center;
}

.center {
	left: 50%;
  text-align: center;
  color: white;
  width: 500;
}


.App-logo {
  height: 40vmin;
  border-radius: 50%;
  margin-bottom: 50px;
  border: 1vh solid #232d3622;
  pointer-events: none;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
}

.header-text{
  padding-left: 12px;
  padding-right: 12px;
}

.header-text::after {
  content: '|';
  animation: blink 0.7s step-end infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.App-link {
  color: #61dafb;
}

footer {
  text-align: center;
  padding: 3px;
  background-color: rgb(255, 255, 255);
  color: white;
}

@keyframes funnyMouseMove {
  0% {
    transform: translate(var(--mouseX, 0px), var(--mouseY, 0px)) rotate(0deg) scale(1);
  }
  20% {
    transform: translate(calc(var(--mouseX, 0px) - 50px), calc(var(--mouseY, 0px) - 50px)) rotate(30deg) scale(1.2);
  }
  40% {
    transform: translate(calc(var(--mouseX, 0px) + 30px), calc(var(--mouseY, 0px) + 30px)) rotate(-60deg) scale(0.8);
  }
  60% {
    transform: translate(calc(var(--mouseX, 0px) - 20px), calc(var(--mouseY, 0px) - 20px)) rotate(90deg) scale(1.5);
  }
  80% {
    transform: translate(calc(var(--mouseX, 0px) + 10px), calc(var(--mouseY, 0px) + 10px)) rotate(-180deg) scale(0.6);
  }
  100% {
    transform: translate(var(--mouseX, 0px), var(--mouseY, 0px)) rotate(360deg) scale(0);
  }
}

.character {
  display: inline-block;
  will-change: transform;
  transition: transform 0.1s ease;
  animation-fill-mode: forwards; 
}


.character:hover {
  animation: funnyMouseMove 1s infinite alternate;
  animation-fill-mode: forwards;  
  animation-timing-function: ease-in-out;  
  color: rgb(21, 255, 0); 
}

.character:not(:hover) {
  color: rgb(0, 0, 0);
}